import logo from './logo.png';
import qrCode from './qr-code.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header App-wrapper">
        <img src={logo} className="App-images" alt="Dodgeball DC" />
        <div>
          <p className="App-text">Our previous league has been cancelled.  We intend to fix that, and make it better.  Sign up for more info!</p>
          <p className="">Site QR Code</p>
          <img src={qrCode} className="App-images App-qr" alt="QR Code" />

        </div>
        <a className="App-link" href="http://eepurl.com/hWRNIX">Sign Up</a>
      </header>
    </div>
  );
}

export default App;
